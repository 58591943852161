<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div
    class="w-full h-full overflow-hidden print:overflow-auto"
    v-if="this.$store.state.CAMPAIGNS.organisationCampaigns.length != 0"
  >
    <div class="flex flex-col w-full h-full">
      <select-report-type-menu
        :show-test-btn="true"
        :show-questionnaire-btn="showQuestionnaireBtn"
        :questionnaireId="(showQuestionnaireBtn) ?currentCampaign.QuestionnaireBatteries[0].id : '' "
      />
    </div>
  </div>
</template>

<script>
import SelectReportTypeMenu from '../components/SelectReportTypeMenu.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    SelectReportTypeMenu
  },
  props: {
    campaignId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      campaignBatteries: [],
      selected: '',
      showPowerBIReport: false
    }
  },
  methods: {
    ...mapActions({ setCurrentCampaign: 'ANALYTICS/setCurrentCampaign' })
  },
  computed: {
    ...mapGetters({
      currentCampaign: 'ANALYTICS/getCurrentCampaign'
    }),

    showQuestionnaireBtn () {
      return (this.currentCampaign && this.currentCampaign.QuestionnaireBatteries) ? this.currentCampaign.QuestionnaireBatteries.length > 0 : false
    }
  }
}
</script>

<style>

</style>
