<template>
  <div class="flex w-full space-x-3 h-5/6">
    <div class="w-1/2 h-full p-8">
      <router-link
        v-if="showTestBtn"
        class="flex flex-col items-center p-12 justify-center w-full h-full rounded-md cursor-pointer select-none bg-primary-light"
        :to="{ name: 'Scoreboard', params: { campaignId: this.$route.params.campaignId } }"
      >
        <div class="text-center">
          <div class="text-lg lg:text-3xl">
            Cognitive test reports
          </div>
          <h4 class="hidden mt-2 text-center 2xl:block">
            Scoreboard / Summary / Full report
          </h4>
        </div>
        <div class="hidden 2xl:block">
          <img
            src="@/assets/chartExamples/spider_chart_example.png"
            width="300px"
            height="300px"
          >
        </div>
      </router-link>
    </div>
    <div class="w-1/2 h-full p-8">
      <router-link
        v-if="showQuestionnaireBtn"
        :to="{ name: 'Personality', params: { id: this.questionnaireId}, query : {campaignId: this.$route.params.campaignId} }"
        class="flex flex-col items-center p-12 justify-center w-full h-full text-3xl rounded-md cursor-pointer select-none bg-primary-light"
      >
        <div class="text-center">
          <div class="text-lg lg:text-3xl">
            Personality++ report
          </div>
          <h4 class="hidden mt-2 text-center 2xl:block">
            &nbsp;
          </h4>
        </div>
        <div
          class="items-center justify-center hidden 2xl:flex"
          style="
            height:300px"
        >
          <img
            src="@/assets/chartExamples/bell_curve_example.png"
            width="500px"
          >
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showTestBtn: Boolean,
    showQuestionnaireBtn: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    questionnaireId: String
  }
}
</script>

<style>
</style>
